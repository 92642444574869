import React from 'react'
import './style.scss'
import footicon from '../images/BBA_Logo-insig_dark.svg';

const Footer = () => (
  <div>
    <footer className='footer center stonebg white'>
      <div className='content'>
        <div className='container'>
          <div className='columns'>
            <div className='column'>
              <div className='footlocker is-flex'>
                <div>
                <img className="footlogo" src={footicon} alt="footer logo" /> 
                </div>
                <div >
                ©2020 Baker Barrios Architects<br/>
                All Rights Reserved<br/>
                <a href="#">Terms of Use</a>  |  <a href="#">Privacy Policy</a>
                </div>
              </div>
            </div>
            <div className='column has-text-right'>
            <a href="#">Main Site</a>  |  <a href="#">Resources</a>  |  <a href="#">Contact</a><br/><br/>
            AA0002981  +  LC26000427
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
  )
  
  export default Footer
  